import {createRouter, createWebHistory} from 'vue-router'
//-异步加载组件

//-路由规则
const myRoutes = [
    {
        //落地页
        path: '/share',
        name: 'share',
        component: () => import( '@/views/share/Share.vue')
    },
    {
        //浮点活动页
        path: '/float/activity',
        name: 'activity',
        component: () => import( '@/views/float/Activity.vue')
    },
    {
        path: "/404",
        name: '404',
        component: () => import( '@/views/404.vue')
    }
]

//-创建路由对象
const router = createRouter({
    history: createWebHistory(),
    routes: myRoutes
})

//-将路由暴露出去，其他文件才能访问
export default router