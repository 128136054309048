import { createApp } from 'vue'
import App from './App.vue'
//导入axios框架
// import Axios from 'axios'
//导入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
//导入ElementPlus框架
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'

//导入 router框架
import Router from './router/index.js'

import './registerServiceWorker'

const app = createApp(App)
app.use(ElementPlus)
app.use(Router)
app.mount('#app')
